<template>
  <v-menu
    v-bind="menuProps">
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="innerValue"
        :rules="rules"
        v-bind="$attrs"
        type="text"
        autocomplete="false"
        v-on="on" />
    </template>
    <v-list>
      <template v-if="items.length > 0">
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="clickSelectItem(item)"
          @keydown.enter="clickSelectItem(item)">
          <v-list-item-title>
            <span
              v-for="(text, i) in textTemplate"
              :key="`${text}-${i}`"
              :class="{'font-bold': text === innerType}">
              <span
                v-if="i !== 0"
                class="mx-2">
                >
              </span>
              <span>
                {{ item[text] || '' }}
              </span>
            </span>
          </v-list-item-title>
        </v-list-item>
      </template>
      <v-list-item v-else>
        <v-list-item-title>{{ $t('global.notFound') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { searchAddressByDistrict, searchAddressByAmphoe, searchAddressByProvince, searchAddressByZipcode } from 'thai-address-database'

export default {
  props: {
    value: {
      type: [String, Object],
      default: ''
    },
    type: {
      type: String,
      required: true,
      validator: (type) => [
        'sub-district',
        'district',
        'province',
        'postcode'
      ].some((t) => t === type)
    },
    rules: {
      type: [String, Object, Array],
      default: null
    },
    menuProps: {
      type: Object,
      default: () => ({
        maxHeight: 304,
        offsetY: true,
        offsetOverflow: true,
        transition: false
      })
    }
  },
  data () {
    return {
      textTemplate: [
        'district',
        'amphoe',
        'province',
        'zipcode'
      ]
    }
  },
  computed: {
    langauge () {
      return this.$i18n.locale
    },
    province () {
      return {
  'Bangkok': 'Bangkok',
  'Samut Prakan': 'Samut Prakan',
  'Nonthaburi': 'Nonthaburi',
  'Pathum Thani': 'Pathum Thani',
  'Phra Nakhon Si Ayutthaya': 'Phra Nakhon Si Ayutthaya',
  'Ang Thong': 'Ang Thong',
  'Loburi': 'Loburi',
  'Sing Buri': 'Sing Buri',
  'Chai Nat': 'Chai Nat',
  'Saraburi': 'Saraburi',
  'Chon Buri': 'Chon Buri',
  'Rayong': 'Rayong',
  'Chanthaburi': 'Chanthaburi',
  'Trat': 'Trat',
  'Chachoengsao': 'Chachoengsao',
  'Prachin Buri': 'Prachin Buri',
  'Nakhon Nayok': 'Nakhon Nayok',
  'Sa Kaeo': 'Sa Kaeo',
  'Nakhon Ratchasima': 'Nakhon Ratchasima',
  'Buri Ram': 'Buri Ram',
  'Surin': 'Surin',
  'Si Sa Ket': 'Si Sa Ket',
  'Ubon Ratchathani': 'Ubon Ratchathani',
  'Yasothon': 'Yasothon',
  'Chaiyaphum': 'Chaiyaphum',
  'Amnat Charoen': 'Amnat Charoen',
  'Nong Bua Lam Phu': 'Nong Bua Lam Phu',
  'Khon Kaen': 'Khon Kaen',
  'Udon Thani': 'Udon Thani',
  'Loei': 'Loei',
  'Nong Khai': 'Nong Khai',
  'Maha Sarakham': 'Maha Sarakham',
  'Roi Et': 'Roi Et',
  'Kalasin': 'Kalasin',
  'Sakon Nakhon': 'Sakon Nakhon',
  'Nakhon Phanom': 'Nakhon Phanom',
  'Mukdahan': 'Mukdahan',
  'Chiang Mai': 'Chiang Mai',
  'Lamphun': 'Lamphun',
  'Lampang': 'Lampang',
  'Uttaradit': 'Uttaradit',
  'Phrae': 'Phrae',
  'Nan': 'Nan',
  'Phayao': 'Phayao',
  'Chiang Rai': 'Chiang Rai',
  'Mae Hong Son': 'Mae Hong Son',
  'Nakhon Sawan': 'Nakhon Sawan',
  'Uthai Thani': 'Uthai Thani',
  'Kamphaeng Phet': 'Kamphaeng Phet',
  'Tak': 'Tak',
  'Sukhothai': 'Sukhothai',
  'Phitsanulok': 'Phitsanulok',
  'Phichit': 'Phichit',
  'Phetchabun': 'Phetchabun',
  'Ratchaburi': 'Ratchaburi',
  'Kanchanaburi': 'Kanchanaburi',
  'Suphan Buri': 'Suphan Buri',
  'Nakhon Pathom': 'Nakhon Pathom',
  'Samut Sakhon': 'Samut Sakhon',
  'Samut Songkhram': 'Samut Songkhram',
  'Phetchaburi': 'Phetchaburi',
  'Prachuap Khiri Khan': 'Prachuap Khiri Khan',
  'Nakhon Si Thammarat': 'Nakhon Si Thammarat',
  'Krabi': 'Krabi',
  'Phangnga': 'Phangnga',
  'Phuket': 'Phuket',
  'Surat Thani': 'Surat Thani',
  'Ranong': 'Ranong',
  'Chumphon': 'Chumphon',
  'Songkhla': 'Songkhla',
  'Satun': 'Satun',
  'Trang': 'Trang',
  'Phatthalung': 'Phatthalung',
  'Pattani': 'Pattani',
  'Yala': 'Yala',
  'Narathiwat': 'Narathiwat',
  'buogkan': 'buogkan',
  '-': '-'
}
    },
    innerValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    innerType () {
      switch (this.type) {
        case 'sub-district': return 'district'
        case 'district': return 'amphoe'
        case 'province': return 'province'
        case 'postcode': return 'zipcode'
        default: return null
      }
    },
    items () {
      if (this.innerValue) {
        switch (this.innerType) {
          case 'district': return searchAddressByDistrict(this.innerValue) || []
          case 'amphoe': return searchAddressByAmphoe(this.innerValue) || []
          case 'province': return searchAddressByProvince(this.innerValue) || []
          case 'zipcode': return searchAddressByZipcode(this.innerValue) || []
          default: return []
        }
      }
      return []
    }
  },
  mounted () {
    // console.log(this.searchAddressByDistrict1)
  },
  methods: {
    clickSelectItem (item) {
      this.$emit('select', {
        subDistrict: item?.district || '',
        district: item?.amphoe || '',
        province: item?.province || '',
        postcode: item?.zipcode || ''
      })
    },
    translate (items) {
      return items.map((item) => ({
        district: this.$t(`.${item.district}`),
        amphoe: this.$t(`.${item.amphoe}`),
        province: this.$t(`province2.${item.province}`),
        zipcode: item.zipcode
      }))
    }
  }
}
</script>

<style scoped>
.font-bold {
  font-weight: 700;
}
</style>
